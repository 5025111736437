(function ($, dell) {
    dell.Components = dell.Components || {};
    dell.Components.ProductSelector = dell.Components.ProductSelector || {};
    (function () {

        this.Url = () => {
            if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                let globalLwp = Dell.OnlineUtils.Lwp;
                let baseUrl = 'https://www.dell.com/support/components';
                let compName = 'product-selector';
                if (Dell.ComponentLoader.baseUrls !== undefined && Dell.ComponentLoader.baseUrls[compName]) {
                    baseUrl = Dell.ComponentLoader.baseUrls[compName];
                }
                let urls = {
                    baseUrl: baseUrl,
                    getProducts: 'productselector/allproducts',
                    queryString: `country=${globalLwp.country}&language=${globalLwp.language}&region=${globalLwp.region
                        }&segment=${globalLwp.segment}&customerset=${globalLwp.customerset}&appName=${Dell.ComponentLoader.appNames["product-selector"]}&version=v2`
                };
                return urls;
            }
            return '';
        };

        this.loadPs = (allProductsUrl) => {
            $.ajax({
                type: 'GET',
                url: allProductsUrl,
                contentType: 'application/json',
                cache: false,
                async: true,
                success: function(result) {
                    $("#div-products").html(result);
                    $('#catalog-modal').modal('show');
                },
                failure: function(error) {
                    console.log(error);
                }
            });
        };

        var loadPSelector = this.loadPs;
        this.init = () => {
            let urlPath = this.Url();
            let allProductsUrl = `${urlPath.baseUrl}/${urlPath.getProducts}?${urlPath.queryString}`;
            $("#browseallprod,.browse-all-prod").on("click", function () {
                loadPSelector(allProductsUrl);
            });
        };

        this.catalogProduct = (category) => {
            if (category === "") {
                category = "prod";
            }
            let urlPath = this.Url();
            let allProductsUrl = `${urlPath.baseUrl}/${urlPath.getProducts}?category=${category}&${urlPath.queryString}`;
            $.ajax({
                type: 'GET',
                url: allProductsUrl,
                contentType: 'application/json',
                cache: false,
                async: true,
                success: function (result) {
                    $("#divCatalogContent").html(result);
                },
                failure: function (error) {
                    console.log(error);
                }
            });
        };

        window.CatalogProductSelect = (category, path, appName, description, pathDescription, hasProducts, hasGhostCategory, ghostDesc) => {
            if (typeof window.CatalogFamilyLevel !== "undefined") {
                let callBackFun = window.CatalogFamilyLevel;
                var data = {
                    "category": category,
                    "path": path,
                    "appName": appName,
                    "Description": description,
                    "PathDescription": pathDescription,
                    "HasProducts": hasProducts,
                    "HasGhostCategory": hasGhostCategory,
                    "GhostDesc": ghostDesc
                };
                var returnObj = callBackFun.call(this, data);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                }
            }
            if (category !== 'productlevel') {
                this.catalogProduct(category);
                // url push
                if ((Dell.ComponentLoader.appNames['product-selector'] === 'home' ||
                        Dell.ComponentLoader.appNames['product-selector'] === 'drivers' ||
                        Dell.ComponentLoader.appNames['product-selector'] === 'videos' ||
                        Dell.ComponentLoader.appNames['product-selector'] === 'manuals') &&
                    history.pushState) {
                    if (Dell.OnlineUtils !== 'undefined' && Dell.OnlineUtils.Lwp !== 'undefined') {
                        let globalLwp = Dell.OnlineUtils.Lwp;
                        history.pushState(null,
                            "products",
                            `/support/home/${globalLwp.language}-${globalLwp.country}/` +
                            path +
                            window.location.search);
                    }
                }
            }
        };

        this.popstate = (e) => {
            let path = window.location.pathname;
            if (path.indexOf('/products/') > -1) {
                let EApath = path.substring(path.lastIndexOf('/') + 1);
                if (EApath.toLowerCase() == 'electronics_and_accessories') {
                    setTimeout(function () { history.back(); }, 0);
                }
                else {
                    let pathVal = path.substring(path.indexOf('products/'));
                    let category = pathVal.replace("products", "all-products").replace(/[/]/g, "/esuprt_");
                    this.catalogProduct(category);
                    $('#catalog-modal').modal('show');
                }

            }
            
            else {
                $('#catalog-modal').modal('hide');
            }
        };

        let popstateps = this.popstate;
        window.onpopstate = function (e) {
            if (Dell.ComponentLoader.appNames['product-selector'] === 'home' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'drivers' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'videos' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'manuals') {
                popstateps(e);
            }
        };
        
        window.selectedProduct = (appName, productCode, pathDescription, emcProductId, redirectUrl, description, ghostDesc) => {
            if (typeof window.CatalogProductLevel !== "undefined") {
                let callBackFun = window.CatalogProductLevel;
                var data = {
                    "productCode": productCode,
                    "Description": pathDescription,
                    "appName": appName,
                    "emcProductId": emcProductId,
                    "redirectUrl": redirectUrl,
                    "description": description,
                    "ghostDesc": ghostDesc
                };
                let returnObj = callBackFun.call(this, data);
                if (returnObj !== undefined && returnObj === false) {
                    event.preventDefault();
                } else {
                    this.secureRedirect(redirectUrl);
                }
            } else {
                this.secureRedirect(redirectUrl);
            }
        };

        this.secureRedirect = (url) => {
            window.location.href = url;
        };

        this.loadPs = () => {
            let path = window.location.pathname;
            if (path.indexOf('/products/') > -1) {
                let pathVal = path.substring(path.indexOf('products/'));
                let category = pathVal.replace("products", "all-products").replace(/[/]/g, "/esuprt_");
                let urlPath = this.Url();
                let allProductsUrl = `${urlPath.baseUrl}/${urlPath.getProducts}?category=${category}&${urlPath.queryString}&openmodal=true`;
                loadPSelector(allProductsUrl);
            }
            else {
                $('#catalog-modal').modal('hide');
            }
        };

        let initPs = this.init;
        let loadProduct = this.loadPs;
        $(document).ready(function() {
            initPs();
            if (Dell.ComponentLoader.appNames['product-selector'] === 'home' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'drivers' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'videos' ||
                Dell.ComponentLoader.appNames['product-selector'] === 'manuals') {
                loadProduct();
            }
        });
    }).apply(dell.Components.ProductSelector);
})(jQuery, Dell = window.Dell || {});